<template>
    <div class="recommend-container">
        <MainHeader />
        <CommonTop />
        <div class="page-content-wrap">
            <div class="recommend-banner">
                <img style="cursor: pointer;" :src="recommendData && recommendData.cover" alt="">
            </div>
             <div class="list">
                <div @click="jumpDetail(item.id)" v-for="(item, index) in recommendData.goods" :key="index" class="list-item">
                    <div class="cat-pic">
                    <img :src="item.cover" alt="">
                    </div>
                    <div class="cat-name">{{item.name}}</div>
                </div>
            </div>
        </div>
       
    </div>
</template>
<script>
import MainHeader from '@/components/header/main.vue'
import CommonTop from '@/components/commonTop/index.vue'
export default {
   components: {
    MainHeader,
    CommonTop
  }, 
  data () {
      return {
            recommendList: [],
            recommendType: ''
      }
  },
  created () {
      this.recommendType = this.$route.query.type
      this.getRecommend()
  },
  computed: {
    recommendData () {
      return this.recommendList.filter(item => {
          console.log(11111, this.recommendType, item.type)
        return item.type === Number(this.recommendType)
      })[0]
    }
  },
  methods: {
    // 获取推荐商品
    async getRecommend () {
        const res = await this.$http.get('/api/recommand-goods', {})
        console.log(res)
        this.recommendList = res
    },
    // 跳转详情
    jumpDetail (id) {
      this.$router.push({ path: '/goods/detail', query: { id: id }})
    }
  }
}
</script>
<style scoped lang="scss">
.recommend-container {
    background-color: #f5f6fb;
}
.page-content-wrap {
    padding-bottom: 40px;
    min-height: 500px;
}
.recommend-banner {
    overflow: hidden;
    height: 226px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 2px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .list {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      .list-item {
        width: 192px;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 16px;
        padding-bottom: 20px;
        cursor: pointer;
        margin-right: 9px;
        margin-top: 9px;
        border-radius: 3px;
        &:nth-child(-n + 6) {
          margin-top: 0;
        }
        &:nth-child(6n) {
          margin-right: 0;
        }
        transition: all .2s linear;
        &:hover {
          box-shadow: 0 15px 30px rgba(0,0,0,.1);
          transform: translate3d(0,-2px,0);
          .cat-pic {
            img {
              // transform: scale(1.1);
            }
          }          
        }
        .cat-pic {
          width: 160px;
          height: 160px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 100%;
            max-height: 100%;
            transition: all .3s;
          }
        }
        .cat-name {
          text-align: center;
          margin-top: 20px;
          color: #3385FF;
        }
      }
    }
</style>